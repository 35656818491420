.teeleuchte {
  height: 500px;
  width: 500px;
  image-rendering: pixelated;
  margin: auto;
  margin-top: 50px;
}


.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.header {
  color: white
}

body {
  background-color: #3E3B39;
}